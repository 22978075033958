/**
 *
 * @author Héctor Gabriel Alegre <gabriel.h.alegre@gmail.com>
 * @date 6/10/2020
 *
 */
import rules from '@/util/rules'

export const toFullVuetifyField = (field) => {
  const isText =
    field.includes('string') ||
    field.includes('password') ||
    field.includes('email') ||
    field.includes('url') ||
    field.includes('number')

  const isDate = field.includes('date')

  let component = field
  let counter = null
  let counterMin = null
  let type = null
  if (isText) {
    type = !field.includes('password') ? 'string' : field
    counter = field.split(':')[1] ? Number(field.split(':')[1]) : null
    const counterMax = field.split(':')[2] ? Number(field.split(':')[2]) : null
    if (counterMax) {
      counterMin = counter
      counter = counterMax
    }
    component = 'textField'
  }

  if (isDate) {
    component = 'date'
  }
  const FIELDS = {
    'radio-group': { element: 'v-radio-group', subElement: 'v-radio' },
    text: { element: 'v-textarea', subElement: false, counter, counterMin },
    textField: {
      element: 'v-text-field',
      subElement: false,
      counter,
      counterMin,
      type,
    },
    textarea: {
      element: 'v-textarea',
      subElement: false,
      counter,
      counterMin,
      type,
    },
    chip: {
      element: 'v-chip',
      subElement: false,
      isArray: false,
      items: false,
    },
    'checkbox-group': {
      element: 'v-checkbox',
      subElement: false,
      isArray: true,
    },
    select: {
      element: 'v-select',
      subElement: false,
      isArray: false,
      items: true,
    },
    search: {
      element: 'VAutocomplete',
      subElement: false,
      isArray: false,
      items: true,
    },
    file: {
      element: 'upload-file',
      subElement: false,
      isArray: false,
      items: true,
    },
    editBrowse: { element: 'custom-input-edit-browse', subElement: false },
    browse: { element: 'custom-input-browse', subElement: false },
    date: { element: 'date-picker', subElement: false },
    label: { element: 'div', subElement: false },
    switch: { element: 'v-switch', subElement: false },
    color: { element: 'custom-input-color', subElement: false },
    checkbox: { element: 'v-checkbox', subElement: false },
    disabledInput: { element: 'disabled-input', subElement: false },
    fileDownload: { element: 'file-download', subElement: false },
    btnDownloadFile: { element: 'btn-download-file', subElement: false },
    benefitSelector: { element: 'custom-benefit-selector', subElement: false, items: true },
    benefitTypeSelector: { element: 'custom-benefit-type-selector', subElement: false, items: true },
    list: { element: 'custom-list' },
  }

  return FIELDS[component]
}

export const toCounter = (field) => {
  const isText =
    field.includes('string') ||
    field.includes('password') ||
    field.includes('email') ||
    field.includes('url') ||
    field.includes('number')

  let counter = false
  if (isText) {
    counter = field.split(':')[1] ? Number(field.split(':')[1]) : false
    const counterMax = field.split(':')[2] ? Number(field.split(':')[2]) : null
    if (counterMax) {
      counter = counterMax
    }
  }

  return counter
}

export const toVuetifyField = (field) => {
  const isText =
    field &&
    (field.includes('string') ||
      field.includes('password') ||
      field.includes('email') ||
      field.includes('url') ||
      field.includes('number'))

  const isDate = field.includes('date')

  let component = field || 'div'
  if (isText) {
    component = 'textField'
  }

  if (isDate) {
    component = 'date'
  }

  const FIELDS = {
    textField: 'v-text-field',
    textarea: 'v-textarea',
    color: 'custom-input-color',
    chip: 'v-chip',
    select: 'v-select',
    search: 'VAutocomplete',
    date: 'date-picker',
    editBrowse: 'custom-input-edit-browse',
    browse: 'custom-input-browse',
    file: 'upload-file',
    switch: 'v-switch',
    disabledInput: 'disabled-input',
    fileDownload: 'file-download',
    checkbox: 'checkbox',
    btnDownloadFile: 'btn-download-file',
    benefitSelector: 'custom-benefit-selector',
    benefitTypeSelector: 'custom-benefit-type-selector',
    list: 'custom-list',
  }

  return FIELDS[component]
}

export const toAttributes = (style) => {
  const response = {}

  if (style?.attributes?.length) {
    style.attributes.forEach((element) => {
      const elements = element ? element.split(':') : []
      response[elements[0]] = elements[1] ? elements[1] : true
    })
  }

  return response
}

export const toType = (field) => {
  const isText =
    field.includes('string') ||
    field.includes('email') ||
    field.includes('url') ||
    field.includes('number')

  let type = field

  if (isText) {
    type = 'text'
  }

  return type
}

export const toVuetifyValidate = (field, toFullVuetifyField, dataModel) => {
  const rulesMapped = []
  if (String(field.required) === 'true') {
    rulesMapped.push(rules('required'))
  }
  if (toFullVuetifyField?.counter && Number(toFullVuetifyField.counter)) {
    if (
      toFullVuetifyField?.counterMin &&
      Number(toFullVuetifyField.counterMin)
    ) {
      if (
        Number(toFullVuetifyField.counterMin) ===
        Number(toFullVuetifyField.counter)
      ) {
        rulesMapped.push(
          rules('exactly', Number(toFullVuetifyField.counterMin)),
        )
      } else {
        rulesMapped.push(rules('min', Number(toFullVuetifyField.counterMin)))
        rulesMapped.push(rules('max', Number(toFullVuetifyField.counter)))
      }
    } else {
      rulesMapped.push(rules('max', Number(toFullVuetifyField.counter)))
    }
  }
  if (field.type === 'url') {
    rulesMapped.push(rules('url'))
  }
  if (field.type === 'email') {
    rulesMapped.push(rules('email'))
  }
  if (field.type === 'number') {
    rulesMapped.push(rules('number'))
  }
  if (String(field.required) === 'true' && String(field.multiple) === 'true') {
    rulesMapped.push(rules('array'))
  }

  if (field?.customValidations?.length) {
    field.customValidations.forEach((element) => {
      rulesMapped.push(
        rules(element.validation, element.data, dataModel, field),
      )
    })
  }

  return rulesMapped
}

export const paramMapped = (params, data, dataForm, originalData, submit) => {
  let response = {}
  if (params) {
    const entries = Object.entries(params)
    entries.forEach((element) => {
      if (!data[element[1]]) {
        const field = dataForm.find(e => e.name === element[1])
        if ('falseValue' in field) {
          data[element[1]] = field.falseValue
        } else if ('object' in field) {
          data[element[1]] = { [field.object]: String(field?.multiple) ? [] : null }
        } else {
          data[element[1]] = null
        }
      }

      if (element[0] === 'photo') {
        const blob = data[element[1]][0]
        if (blob?.type) {
          response[element[0]] = data[element[1]]
        }
      } else {
        if (element[0] === '_method' || element[0] === 'token' || !originalData[element[0]] || (originalData[element[0]] && data[element[1]] !== originalData[element[0]])) {
          if (data[element[1]] || submit?.method !== 'POST') {
            response[element[0]] = data[element[1]]
          }
        }
      }
    })
  } else {
    const entries = Object.keys(dataForm)
    entries.forEach((element) => {
      if (!data[dataForm[element].name]) {
        if ('falseValue' in dataForm[element]) {
          data[dataForm[element].name] = dataForm[element].falseValue
        } else if ('object' in dataForm[element]) {
          data[dataForm[element].name] = { [dataForm[element].object]: String(dataForm[element]?.multiple) ? [] : null }
        } else {
          data[dataForm[element].name] = null
        }
      }
    })

    response = data
  }

  return response
}
