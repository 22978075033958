import store from '@/store'
import router from '@/router'
import axios from 'axios'

import { getCookie } from '@/util/cookies'
axios.defaults.baseURL = process.env.VUE_APP_BASE_API // url = base url + request url

const msgMethods = ['post', 'put', 'patch', 'delete']

const noResponseUrl = ['login', 'validate-token']

const txtMsgMethods = {
  post: 'Agregado con éxito',
  put: 'Modificado con éxito',
  patch: 'Modificado con éxito',
  delete: 'Borrado con éxito',
}
// create an axios instance
const service = axios.create({
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000, // request timeout
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    const token = getCookie()
    if (token) {
      config.headers.Authorization = `${token.token_type} ${token.token}`
    }

    return config
  },
  (error) => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  },
)

// response interceptor
// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data
    const successMSg = res?.message?.message || res?.message
    if (successMSg) {
      store.commit('app/SET_ALERT_TXT', {
        timeout: 3700,
        type: 'success',
        txt: successMSg,
      })
      store.commit('app/SET_ALERT', true)
    } else if (!noResponseUrl.includes(response.config.url) && msgMethods.includes(response?.config?.method)) {
      const method = response.config.headers._method || response?.config?.method
      store.commit('app/SET_ALERT_TXT', {
        timeout: 3700,
        type: 'success',
        txt: txtMsgMethods[method.toLowerCase()],
      })
      store.commit('app/SET_ALERT', true)
    }

    return res
  },
  async (error) => {
    const errorStatus = error?.response?.status
    console.log('err' + error) // for debug
    console.log('status ' + errorStatus) // for debug

    let errorMSg = error?.response?.data?.error?.message
    const isObjectMsg = typeof errorMSg === 'object'
    if (isObjectMsg) {
      const itemsMsg = Object.values(errorMSg)
      errorMSg = itemsMsg.reduce(
        (previousValue, currentValue) => previousValue + `<div>${currentValue}</div>`,
        '',
      )
    }

    if (!noResponseUrl.includes(error.config.url) || error.config.url === 'login') {
      if (errorMSg) {
        if (error.response.status === 404) {
          router.push({ path: '/' }).catch((e) => { console.log(e) })
        }
        store.commit('app/SET_ALERT_TXT', {
          timeout: 3700,
          type: 'error',
          txt: errorMSg || 'Error',
        })
        store.commit('app/SET_ALERT', true)
      } else {
        store.commit('app/SET_ALERT_TXT', {
          timeout: 3700,
          type: 'error',
          txt: 'Error!',
        })
        store.commit('app/SET_ALERT', true)
      }
    }

    return Promise.reject(error)
  },
)

export default service
