
export function leadingSlash (str) {
  return str.startsWith('/') ? str : '/' + str
}

export function trailingSlash (str) {
  return str.endsWith('/') ? str : str + '/'
}

export function capitalize (word) {
  return word[0].toUpperCase() + word.slice(1).toLowerCase()
}

export const wait = (timeout) => {
  return new Promise((resolve) => setTimeout(resolve, timeout))
}

export function getVariablesBetweenString (
  word,
  firstCharacter,
  secondCharacter,
) {
  const out = []
  word = word.replaceAll('.', '__')

  word.replace(/({)\w+(})+/g, (e) => {
    out.push(e.replace('{', '').replace('}', '').replaceAll('__', '.'))
    return e
  })

  return out
}

export function replaceAll (str, find, replace) {
  return str ? str.replace(new RegExp(escapeRegExp(find), 'g'), replace) : str
}

export const generateStringFromVariables = ({ title, data }) => {
  let txt = title
  const finds = getVariablesBetweenString(title)
  if (finds?.length && data) {
    finds.forEach((element) => {
      const dataMapped = toPathObject(data, element)
      if (dataMapped && dataMapped !== 'null') {
        txt = replaceAll(txt, `{${element}}`, dataMapped)
      } else {
        if (txt.includes('(')) {
          txt = replaceAll(txt, '(', '')
        }
        if (txt.includes(')')) {
          txt = replaceAll(txt, ')', '')
        }
        txt = replaceAll(txt, `{${element}}`, '')
      }
    })
  }
  return txt
}

function escapeRegExp (string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}
export const idnumber = (number) => {
  if (number) {
    let firstNumber = number.toString()
    firstNumber = String(firstNumber).replace(/(.)(?=(\d{3})+$)/g, '$1.')
    return `${firstNumber}`
  }
}

export const cuit = (number) => {
  if (number) {
    const firstNumber = number.substr(0, 2)
    const secondNumber = number.substr(2, 8)
    const thirdNumber = number.substr(10, 1)
    return `${firstNumber}-${secondNumber}-${thirdNumber}`
  }
}

export const getTokenData = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}
export const months = (number) => {
  const months = {
    '01': 'Enero',
    '02': 'Febrero',
    '03': 'Marzo',
    '04': 'Abril',
    '05': 'Mayo',
    '06': 'Junio',
    '07': 'Julio',
    '08': 'Agosto',
    '09': 'Septiembre',
    10: 'Octubre',
    11: 'Noviembre',
    12: 'Diciembre',
  }
  if (number) {
    return months[number]
  }
  return months
}

export const shortDate = (date) => {
  if (!date) return null
  const dateTrunc = date.split(' ')[0].split('T')[0]
  if (dateTrunc) {
    const [year, month, day] = dateTrunc.split('-')

    return `${day}/${month}/${year}`
  }
  return null
}

export const shortDateTime = (date) => {
  if (!date) return null
  const dateTime = date.split(' ')[0].split('T')[1].split('.')[0]
  const dateTrunc = date.split(' ')[0].split('T')[0]
  if (dateTrunc) {
    const [year, month, day] = dateTrunc.split('-')

    return `${day}/${month}/${year} ${dateTime}`
  }
  return null
}

export const textLong = (date) => {
  if (!date) return null
  const dateTrunc = date.split(' ')[0]
  if (dateTrunc) {
    const [year, month, day] = dateTrunc.split('-')

    return `${day} de ${months(month)} de ${year}`
  }
  return null
}

export const flatArray = (data) => {
  if (!data?.length) return null
  var names = data.map(element => {
    return element.name
  })

  return names.join()
}

export const dateHelpers = { shortDate, textLong, months }

export const toPathObject = (obj, is, value) => {
  if (typeof is === 'string') {
    return toPathObject(obj, is.split('.'), value)
  } else if (is.length === 1 && value !== undefined) {
    obj[is[0]] = value
    return obj[is[0]]
  } else if (is.length === 0) {
    return obj
  } else {
    return obj ? toPathObject(obj[is[0]], is.slice(1), value) : ''
  }
}

export const fomatters = { shortDate, textLong, months, idnumber, cuit, flatArray, shortDateTime }

export const urlToId = (url) => {
  if (url) {
    return replaceAll(url.toLowerCase(), '/', '-')
  }
  return url
}

export const stringToSlug = (str) => {
  str = str.replace(/^\s+|\s+$/g, '') // trim
  str = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
  var to = 'aaaaeeeeiiiioooouuuunc------'
  for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-') // collapse dashes

  return str
}
