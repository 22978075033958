<template>
  <v-dialog
    v-model="modal"
    class="form-modal"
    :persistent="loading || persistent"
    :max-width="maxWidth"
  >
    <v-card v-if="form">
      <v-card-title v-if="form.label || form.title">
        <span class="text-subtitle-1 font-weight-negrita">{{ title }}</span>
      </v-card-title>
      <v-card-text class="px-0 pb-2">
        <component
          :is="type"
          validate
          :status-modal="modal"
          :form="form"
          :items="items"
          :is-custom-loading="false"
          :data-params="params"
          :data-parent-model="dataParentModel"
          :data-source="dataSource"
          @formModalEvent="$emit($event.event,$event.action)"
          @error="$emit('error')"
          @submit="handleSubmit"
          @cancel="handleCancel"
          @isLoading="loading=$event"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

  import { generateStringFromVariables } from '@/util/helpers'
  export default {
    name: 'FormModal',

    components: {},
    props: {
      form: {

        type: [Object, Boolean],
        default: function () {
          return {}
        },
      },
      dataSource: {
        type: [Object, Boolean],
        default: function () {
          return false
        },
      },
      dataParentModel: {
        type: [Object, Boolean],
        default: function () {
          return false
        },
      },
      params: {
        type: [Object, Boolean],
        default: function () {
          return {}
        },
      },
      persistent: {
        type: [Boolean],
        default: function () {
          return false
        },
      },
      items: {
        type: [Array, Boolean],
        default: function () {
          return []
        },
      },
      open: {
        type: Boolean,
        default: false,
      },
      isOverlay: {
        type: Boolean,
        default: false,
      },
      maxWidth: {
        type: String,
        default: '600px',
      },
    },

    data () {
      return { modal: false, loading: false }
    },
    computed: {
      type () {
        return this.form?.steps?.type || this.form?.steps?.[0]?.type || this.form?.type
      },
      title () {
        const title = this.form.label || this.form.title
        const txt = generateStringFromVariables({ title: title || '', data: this.dataSource })

        return txt
      },
    },
    watch: {
      open: function (val) {
        if (val) {
          this.modal = true
        } else {
          this.modal = false
        }
      },
      modal: function (val) {
        if (!val) {
          this.$emit('update:open', false)
        }
      },
    },

    async mounted () {

    },

    methods: {
      handleCancel (event) {
        this.modal = false
        this.$emit('cancel')
      },
      async handleSubmit (data) {
        this.modal = false
        this.$emit('confirm', data)
      },
    },
  }
</script>

<style>

</style>
