<template>
  <VTextField
    v-model="search"
    append-icon="mdi-magnify"
    clearable
    color="secondary"
    dense
    enclosed
    hide-details
    placeholder="Buscar"
    single-line
    :disabled="disabled"
    :loading="disabled"
    @click:append="handleClick"
    @click:clear="handleClick('clear')"
    @keyup.enter="handleClick"
  />
</template>

<script>
  export default {
    name: 'Search',
    props: {

      disabled: {
        type: [Boolean],
        default: false,
      },
    },
    data () {
      return {
        search: null,
      }
    },
    methods: {

      handleClick (action) {
        if (action === 'clear') {
          this.search = null
        }
        this.$emit('click', this.search)
      },
    },
  }
</script>

<style></style>
