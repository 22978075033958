import { mapperColumns, mapperActions } from './utils/columnsVuetify'

export default (data) => {
    let headers = []
    const actions = data.actions?.length ? data.actions : false

    if (actions) {
        const actionsHeader = mapperActions(actions, data.actionTitle)
        if (actionsHeader) {
            headers = [actionsHeader]
        }
    }
    return {
        ...data,
        sourceActions: data.source?.actions?.length ? data.source.actions : [],
        headers: data.columns ? [...headers, ...data.columns.filter((obj) => obj.hidden !== 'true').map((obj) => mapperColumns(obj))] : [],
    }
}
