<template>
  <VRow
    align="center"
    justify="start"
    class="py-1"
  >
    <VCol cols="auto">
      <div
        v-if="label"
        class="text-headline"
      >
        {{ label }}
      </div>
      <div class="text-subtitle-1">
        {{ textValue }}
      </div>
    </VCol>
    <VCol
      v-if="edit"
      cols="auto"
      class="px-1"
    >
      <custom-btn
        :icon="editIcon"
        :attributes="{icon:true,'x-small':true}"
        :tooltip="editTooltip"
        @handleClick="handleEdit"
      />
    </vcol>
    <VCol
      v-if="showHistory"
      cols="auto"
      class="px-1"
    >
      <custom-btn
        :icon="showIcon"
        :attributes="{icon:true,'x-small':true}"
        :tooltip="showTooltip"
        @handleClick="showHistoryEvent"
      />
    </vcol>
    <form-modal
      max-width="700px"
      :form="form"
      :items="items"
      :open.sync="dialogForm"
      @confirm="confirmModalAction"
    />
  </VRow>
</template>

<script>
  import { generateStringFromVariables } from '@/util/helpers'
  import tableMapper from '@/mappers/tables'
  export default {
    name: 'CustomInputEditBrowse',
    props: {
      value: {
        type: [Number, String, Boolean, Array],
        default: function () {
          return ''
        },
      },
      field: {
        required: true,
        type: Object,
        default: function () {
          return {}
        },
      },
      label: {
        type: [String, null],
        default: '',
      },
      editIcon: {
        type: [String, null],
        default: 'pencil',
      },
      editTooltip: {
        type: [String, null],
        default: 'Editar',
      },
      model: {
        type: Object,
        default: function () {
          return {}
        },
      },
      showTooltip: {
        type: [String, null],
        default: 'Ver histórico',
      },
      showIcon: {
        type: [String, null],
        default: 'history',
      },
      edit: {
        type: Boolean,
        default: false,
      },
      showHistory: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        dialogForm: false,
        form: false,
        items: [],
      }
    },
    computed: {
      textValue () {
        let txt = null

        if (this.field?.propertyLabel && this.value?.[0]) {
          txt = generateStringFromVariables({ title: this.field.propertyLabel, data: this.value[0] })
        }
        return txt !== 'null' ? txt || '-' : '-'
      },
    },
    methods: {
      confirmModalAction (data) {
        this.$emit('reload-form', { ...data, field: this.field })
        this.reset()
      },
      reset () {
        this.items = []
        this.form = false
      },
      handleEdit () {
        this.reset()

        let form = null

        try {
          if (this.field?.form?.edit) {
            form = require(`@/jsons/${this.field.form.edit}.json`)
          }
        } catch {
          console.log('No se encontró el json')
        }

        this.form = form || false
        this.dialogForm = !!form
      },
      showHistoryEvent () {
        this.reset()

        let form = null
        try {
          if (this.field?.form?.history) {
            form = require(`@/jsons/${this.field.form.history}.json`)
            if (form) {
              form = tableMapper(form)
              this.items = this.value
            }
          }
        } catch (e) {
          console.log('No se encontró el json')
        }

        this.form = form || false
        this.dialogForm = !!form
      },
    },
  }

</script>

<style>

</style>
