<template>
  <div>
    <VMenu
      v-if="!readonly"
      v-model="menu"
      :close-on-content-click="false"
      min-width="auto"
      offset-y
      v-bind="{...position}"
      transition="scale-transition"
      z-index="204"
    >
      <template #activator="{on, attrs}">
        <VTextField
          v-model="computedFecha"
          v-mask="changing?'':timeRangeMask"
          :readonly="readonly"
          color="insti"
          :rules="rules"
          :label="label"
          placeholder=""
          :clearable="!readonly"
          v-bind="attrs"
          append-icon="mdi-calendar"
          :hint="msgError"
          v-on="on"
          @keydown="changing=false"
          @change="changeInput"
          @click:clear="data=null"
        />
      </template>
      <VDatePicker
        v-model="data"
        v-bind="attr"
        locale="es-AR"
        no-title
        :readonly="readonly"
        @input="change"
      />
    </VMenu>
    <disabled-input
      v-else
      :value="computedFecha"
      :label="label"
    />
  </div>
</template>

<script>
  import { dateHelpers } from '@/util/helpers'
  export default {
    name: 'DatePicker',
    props: {
      attributes: {
        type: [String, Number, Boolean, Object, Array],
        default: null,
      },
      rules: {
        type: Array,
        default: function () {
          return []
        },
      },
      model: {
        type: [Object],
        default: function () {
          return {}
        },
      },
      value: {
        type: [String, Number, Boolean, Object, Array],
        default: '',
      },
      format: {
        type: String,
        default: 'shortDate',
      },
      label: {
        type: String,
        default: 'Fecha',
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      eventsBus: Object,
    },
    data () {
      return {
        menu: false,
        data: '',
        attr: {},
        msgError: 'dd/mm/aaaa',
        changing: true,
      }
    },
    computed: {
      computedFecha: {
        get () {
          return dateHelpers[this.format](this.data)
        },

        set (newName) {
          return newName
        },
      },
      position () {
        return this.attributes?.position ? { [this.attributes.position]: true } : { bottom: true }
      },
    },
    watch: {
      data (val) {
        this.$emit('changeCustomInput', val)
      },
      value (val) {
        this.data = val
      },
      model (val) {
        this.checkFieldDepend()
      },

    },
    mounted () {
      this.eventsBus.$on('change', this.checkFieldDepend)
      this.eventsBus.$on('openForm', this.checkFieldDepend)
    },
    methods: {
      timeRangeMask (value) {
        const date = value.split('/')
        const cuantitySlash = (value.match(/\//g) || []).length
        let day = [/^[0-3]?$/, /^[0-9]$/]
        let month = [/^[0-9]?$/, /^[0-9]?$/]
        const year = [/^[0-9]?$/, /^[09876]?$/, /^[0-9]?$/, /^[0-9]?$/]

        if (date[0]?.length === 1) {
          if (Number(date[0]) === 3) {
            day = [/^[0-3]?$/, /^[0-1]$/]
          }
          if (cuantitySlash > 0) {
            day = [/^[0-9]?$/, '/']
          }
        } else if ((date[0]?.length === 2 && cuantitySlash > 0) || date[0]?.length === 3) {
          day = [/^[0-3]?$/, /^[0-9]$/, '/']
          if (Number(date[0][0]) === 3) {
            day = [/^[0-3]?$/, /^[0-1]$/, '/']
          }
        }

        if (date[1]?.length === 1) {
          if (Number(date[1]) > 1 || cuantitySlash === 2) {
            month = [/^[0-9]?$/]
            if (Number(date[1]) === 1) {
              month = [/^[0-9]?$/, /^[0-2]$/]
            }
            if (cuantitySlash > 1) {
              month = [/^[0-9]?$/, '/']
            }
          }
        } else if ((date[1]?.length === 2 && cuantitySlash > 1) || date[1]?.length === 3) {
          month = [/^[0-9]?$/, /^[0-9]?$/, '/']
          if (Number(date[1][0]) === 1) {
            month = [/^[0-9]?$/, /^[0-2]$/, '/']
          }
        } else if (date[1]?.length === 2 && Number(date[1]) > 12) {
          month = [/^[0-9]?$/, '/']
        }

        return [...day, ...month, ...year]
      },

      changeInput (value) {
        if (value) {
          const date = value.split('/')
          const day = date[0].length === 2 ? date[0] : `0${date[0]}`
          const month = date[1].length === 2 ? date[1] : `0${date[1]}`
          const year = date[2].length === 4 ? date[2] : ''
          const validDate = `${year}-${month}-${day}`
          const timestamp = Date.parse(validDate)
          if (year.length === 4 && validDate && timestamp && !isNaN(timestamp)) {
            let valid = true
            if (this.attr?.min) {
              const timestampMin = Date.parse(this.attr.min)
              if (timestamp < timestampMin) {
                valid = false
              }
            }
            if (this.attr?.max) {
              const timestampMax = Date.parse(this.attr.max)
              if (timestamp > timestampMax) {
                valid = false
              }
            }
            if (valid) {
              this.data = validDate
              this.menu = false
              this.changing = false
              return
            }
          }
        }

        this.changing = true
        this.data = null
        this.msgError = 'Fecha no válida'
        this.menu = false
      },
      change () {
        this.changing = true
        this.menu = false
      },
      checkFieldDepend () {
        const attr = {
          min: '1700-01-01',
        }

        const date = new Date()

        const currentDay = date.getDate()
        const currentMonth = date.getMonth() + 1
        const year = date.getFullYear()
        const day = String(currentDay).length === 2 ? currentDay : `0${currentDay}`
        const month = String(currentMonth).length === 2 ? currentMonth : `0${currentMonth}`
        const currentDate = `${year}-${month}-${day}`

        if (this.attributes?.min === 'from') {
          const min = this.model?.from
          if (min) {
            attr.min = min
          }
        }
        if (this.attributes?.min === 'today') {
          attr.min = currentDate
        }

        if (this.attributes?.max === 'today') {
          attr.max = currentDate
        }
        this.attr = attr
      },
    },
  }
</script>

<style>
[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}
</style>
