<template>
  <div>
    <div
      v-if="label"
      class="text-headline text-none "
    >
      {{ label }}
    </div>
    <div class="text-subtitle-1">
      {{ value }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'DisabledInput',
    props: {
      value: {
        type: [Number, String, null, Array],
        default: function () {
          return ''
        },
      },
      label: {
        type: [String, null],
        default: '',
      },

    },
  }
</script>

<style>

</style>
