<template>
  <v-app id="myApp">
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>
    <VSnackbar
      v-model="alert"
      right
      :timeout="alertTxt.timeout"
      top
    >
      <VAlert
        :type="alertTxt.type"
        v-html="alertTxt.txt"
      />
    </VSnackbar>
    <form-modal
      :form="formChangePassword"
      :data-source="{cuit}"
      :open.sync="modalChangePassword"
    />
  </v-app>
</template>

<script>
// Styles

  import { get, sync } from 'vuex-pathify'
  export default {
    name: 'App',
    metaInfo: {
      title: 'Asistencia',
      titleTemplate: '%s | Dashboard',
      htmlAttrs: { lang: 'es' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
    },
    data: () => ({

      formChangePassword: require('@/jsons/login/recover-session.json'),

    }),

    computed: {
        ...sync('app', ['alert', 'modalChangePassword']),
        ...get('app', ['alertTxt']),
        cuit: get('user/cuit'),
    },
  }
</script>
