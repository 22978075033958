export const mapperColumns = (data) => ({
    ...data,
    text: data.labeling?.label,
    align: data.labeling?.style?.align,
    width: data.labeling?.style?.width,
    sortable: String(data.sorting?.sortable) === 'true',
    value: data.name,
    property: data.property,
    modifying: data.modifying,
    type: data.type,
    cellClass: data.class,
    required: String(data.required) === 'true',
    style: data.style,
})

export const mapperActions = (items, actionTitle) => {
    let response = false
    if (items?.length) {
        response = {
            cellClass: '',
            text: actionTitle || 'Acciones',
            align: 'start',
            sortable: false,
            value: 'actions',
            data: items,
            modifying: false,
            width: items.length > 3 ? '170px' : '140px',
        }
    }
    return response
}
