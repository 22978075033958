<template>
  <VRow
    align="center"
    justify="start"
    class="py-1"
  >
    <custom-loading :loading="loading" />
    <VCol cols="12">
      <span class="text-subtitle-1 font-weight-negrita">
        {{ label }}
      </span>
      <component
        :is="type"
        :form="form"
        :items="items"
        :model="model"
        :is-custom-loading="false"
        @add="handleAdd"
        @edit="handleEdit"
        @del="handleDel"
      />
    </VCol>
    <form-modal
      :form="dataToSave"
      :open.sync="dialogForm"
      :data-source="dataSource"
      :data-parent-model="model"
      :params="dataParams"
      @confirm="confirmModalAction"
    />
  </VRow>
</template>

<script>
  import { genericRequest } from '@/api/modules'
  import tableMapper from '@/mappers/tables'
  import { generateStringFromVariables } from '@/util/helpers'
  export default {
    name: 'CustomInputBrowse',
    props: {
      value: {
        type: [Array],
        default: function () {
          return []
        },
      },
      field: {
        required: true,
        type: Object,
        default: function () {
          return {}
        },
      },
      label: {
        type: [String, null],
        default: '',
      },

      model: {
        type: Object,
        default: function () {
          return {}
        },
      },

    },
    data () {
      return {
        loading: false,
        dataToSave: false,
        dataParams: null,
        dialogForm: false,
        dataSource: false,
      }
    },
    computed: {
      type () {
        return this.form?.steps?.type || this.form?.steps?.[0]?.type || this.form?.type
      },
      items () {
        return this.value?.length ? this.value : []
      },
      form () {
        let form = null
        try {
          if (this.field?.form?.history) {
            form = require(`@/jsons/${this.field.form.history}.json`)
            if (form) {
              form = tableMapper(form)
            }
          }
        } catch (e) {
          console.log('No se encontró el json')
        }

        return form || false
      },
    },
    methods: {
      async handleDel (data) {
        this.loading = true
        try {
          const url = generateStringFromVariables({
            title: data.value.href,
            data: { person_uuid: this.model.uuid, ...data.item },
          })
          const response = await genericRequest({
            url: url,
            method: data.value.method,
            service: data.value.service,
          })

          this.$emit('reload-form', { response: response, field: this.field })
          this.reset()
        } catch (error) {

        } finally {
          this.loading = false
        }
      },
      handleAdd (data) {
        this.reset()

        let form = null

        try {
          form = data.event?.form ? require(`@/jsons/${data.event.form}.json`) : false
        } catch {
          console.log('No se encontró el json')
        }

        this.dataToSave = form || false
        this.dialogForm = true
      },
      async handleEdit (data) {
        this.reset()

        if (data?.value?.event?.form) {
          let form = null

          try {
            form = data.value?.event?.form ? require(`@/jsons/${data.value.event.form}.json`) : false
          } catch {
            console.log('No se encontró el json')
          }

          this.dataParams = { data }
          this.dataSource = { person_uuid: this.model.uuid, ...data.item }
          this.dataToSave = form || false
          this.dialogForm = true
        }
      },
      reset () {
        this.dataParams = null
        this.dataToSave = false
        this.dataSource = false
      },
      confirmModalAction (data) {
        this.$emit('reload-form', { ...data, field: this.field })
        this.reset()
      },
    },
  }

</script>

<style>

</style>
