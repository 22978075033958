import Vue from 'vue'
import Vuetify from 'vuetify'
import { es } from 'vuetify/lib/locale'
Vue.use(Vuetify)

const light = {
  primary: '#673F86',
  secondary: '#C5CAE9',
  accent: '#e91e63',
  info: '#00CAE3',
  success: '#4CAF50',
  warning: '#FB8C00',
  error: '#FF5252',
  menuBg: '#304156',
  vividBlue: '#2196F3',
  pureOrange: '#FF9800',
  sendBtn: '#4165AE',
  greyTxt: '#333333',
  lightGreyTxt: '#444444',
  greyDivider: '#EEEEEE',
  backgroundLayouts: '#E5E5E5',
  menuListados: '#FFFFFF',
  reemplazoTxt: '#D72825',
  orange: '#F2A74E',
  red: '#DB3B30',
}

const dark = {
  primary: '#673F86',
  secondary: '#1428a0',
  success: '#4CAF50',
  orange: '#F2A74E',
  red: '#DB3B30',
}

export default new Vuetify({
  breakpoint: { mobileBreakpoint: 960 },
  lang: {
    locales: { es },
    current: 'es',
  },
  icons: {
    values: { expand: 'mdi-menu-down' },
  },
  theme: {
    themes: {
      dark,
      light,
    },
  },
})
