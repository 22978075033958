<template>
  <qr-code
    :size="180"
    class=""
    :text="url"
    error-level="L"
  />
</template>

<script>
  import Vue from 'vue'
  import VueQRCodeComponent from 'vue-qrcode-component'
  Vue.component('QrCode', VueQRCodeComponent)
  export default {
    name: 'GenericQr',
    props: {
      url: {
        required: true,
        type: String,
        default: function () {
          return ''
        },
      },
    },

    data: () => ({}),

    methods: {

    },

  }
</script>
